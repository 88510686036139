import { createApp } from 'vue'
import App from './App.vue'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGYk-QslhXi_pYKyycRe0HRImwzTKoyjs",
  authDomain: "doors-e85de.firebaseapp.com",
  projectId: "doors-e85de",
  storageBucket: "doors-e85de.appspot.com",
  messagingSenderId: "294298049980",
  appId: "1:294298049980:web:48a1084e6fa1c793ad5fb9",
  measurementId: "G-RWLWB24G5J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
createApp(App).mount('#app')
