<template>
  <img class="sigil-logo" alt="sigil logo" src="./assets/SIGIL.png">
  <CityOfDoors msg="Welcome to Sigil"/>

</template>

<script>

import CityOfDoors from './components/Sigil.vue'




export default {
  name: 'App',
  components: {
    CityOfDoors,
  
}
}
</script>

<style>
  
body {
  background-color: #13183d;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #13183d;
  margin-top: 60px;
  align-items: center;

}

.sigil-logo {
  align-items: center;
}

@media only screen and (max-width: 600px) {
  body {
    margin-left:auto;
   
    
  }

  img{
    max-width: 100%;
    display: block;
  }
}


</style>
